// src/components/landing/style/hero.scss


.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    background: linear-gradient(to bottom, #F8F4F4, #DAD8D8);
    height: 80%;
    width: 80%;
    margin-top: 3rem;
    margin-left: 7%;

    .hero-content {
        max-width: 50%;  // Fixed width for content
        margin: 0 1rem;  // Left and right spacing
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: -3rem; // Move text further up
        margin-left: 2rem; // Move text further right

        h1 {
            font-size: 3.4rem;
            color: #006640; // Slightly darker green
            line-height: 1.2;
            margin-bottom: 1.2rem;
        }

        .hero-button {
            font-size: 1.2rem;
            color: #006640;
            border: 2px solid #006640;
            padding: 0.5rem 1.5rem;
            background-color: transparent;
            text-decoration: none;
            font-weight: 600;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
                background-color: #006640;
                color: #ffffff;
            }
        }
    }

    .hero-image {
        max-width: 80%; // Increase image size by allocating more space
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 8px;
        }
    }
}

// Responsive adjustments
@media (max-width: 768px) {
    .hero {
        flex-direction: column; // Stack content vertically
        padding: 1.5rem;
        align-items: center; // Center the content
        margin-left: 4%;

        .hero-image {
            max-width: 100%; // Make the image take full width
            padding: 0; // Remove padding

            img {
                width: 100%;
                height: auto; // Keep aspect ratio
            }
        }

        .hero-content {
            max-width: 80%; // Adjust fixed width for smaller screens
            align-items: center;
            padding: 1rem;
            margin-top: 1rem; // Add space above the text

            h1 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }

            .hero-button {
                padding: 0.5rem 2rem;
            }
        }
    }
}
