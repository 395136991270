// src/components/sections/style/store.scss

.store {
    padding: 2rem;
    text-align: center;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    .product-grid {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 -1rem;

        .product-card {
            flex: 1 1 calc(21% - 2rem);
            margin: 1rem;
            padding: 1rem;
            border-radius: 8px;
            background-color: white;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            box-sizing: border-box; // Prevents overflow issues by including padding in width calculations

            h2 {
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-radius: 8px;
                margin-bottom: 1rem;
            }

            p {
                margin: 1rem 0;
            }

            .order-button {
                background-color: #006640;
                color: white;
                border: none;
                border-radius: 4px;
                padding: 0.5rem 1rem;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #004d30;
                }
            }
        }
    }
}

// Responsive adjustments
@media (max-width: 768px) {
    .store {
        padding: 1.5rem; // Adds more padding to avoid overlap

        h1 {
            font-size: 2rem;
        }

        .product-grid {
            flex-direction: column;
            align-items: center; // Centers cards in single column

            .product-card {
                flex: 1 1 100%;
                max-width: 100%; // Ensures cards take full width
                margin: 1rem 0;  // Adds space between cards and prevents overlap
                padding: 1rem;
            }
        }
    }
}
