.gallery {
    width: 80%;
    margin: 0 auto;
    padding: 2rem 0;
    text-align: center;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        color: #333;
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr); // Four images per row on larger screens
        gap: 1rem;
    }

    .gallery-item {
        width: 100%;
        height: 200px; // Fixed height for a consistent aspect ratio
        overflow: hidden;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

// Responsive adjustments
@media (max-width: 768px) {
    .gallery {
        display: flex;
        flex-direction: column; // Stack images vertically
        align-items: center;
        gap: 1rem;
    }

    .gallery-item {
        height: 200px; // Keeps the same height for a consistent layout on mobile
        display: flex;
        flex-direction: column; // Stack images vertically
        align-items: center;
    }
}
