// src/components/landing/style/navbar.scss

.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .logo {
        font-size: 1.5rem;
        font-weight: bold;
        color: #333;
        text-decoration: none;
    }

    .hamburger {
        display: none;
        font-size: 2rem;
        cursor: pointer;
        z-index: 1001; // Ensure it stays above the menu when open
    }

    .menu {
        display: flex;
        gap: 1.5rem;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            a {
                text-decoration: none;
                color: #333;
                font-size: 1rem;
                font-weight: 500;
                transition: color 0.3s ease;

                &:hover {
                    color: #007BFF;
                }
            }
        }
    }
}

// Responsive layout for tablets
@media (max-width: 1024px) {
    .navbar {
        padding: 1rem 1.5rem;
    }

    .menu {
        gap: 1rem;

        li a {
            font-size: 1.1rem;
        }
    }
}

// Responsive layout for mobile view
@media (max-width: 768px) {
    .navbar {
        flex-direction: row; // Ensure logo and icon are in one line
        justify-content: space-between; // Space between logo and icon
        padding: 1rem 1.5rem;

        .logo {
            margin-bottom: 0; // Remove bottom margin for inline alignment
            text-align: left; // Align logo to the left
        }

        .hamburger {
            display: block;
        }

        .menu {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.3s ease-out;

            &.open {
                max-height: 100%; // Adjust the value based on the number of menu items
                transition: max-height 0.3s ease-in;
            }

            li {
                width: 100%;
                text-align: center;

                a {
                    display: block;
                    font-size: 1.2rem; // Slightly larger font for better accessibility on small screens
                    padding: 1rem;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}