// src/components/sections/style/about.scss

.about-section {
    display: flex;
    width: 100%;  // Adjusted width to make the card more compact
    max-height: 300px;  // Reduced max-height to make the card more compact
    margin-bottom: 1rem;  // Reduced margin-bottom to make the card more compact
    flex-direction: column;

    .about-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: auto;
            max-height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    .about-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #F8F4F4;
        padding: 0.5rem;  // Reduced padding to make the card more compact

        h1 {
            font-size: 1.5rem;  // Reduced font-size to make the card more compact
            text-align: center;
            margin-bottom: 0.5rem;
        }

        p {
            margin-bottom: 0.5rem;
            line-height: 1.4;
            text-align: center;
            font-size: 0.9rem;  // Reduced font-size to make the card more compact
        }
    }
}

// Responsive adjustments for tablets and larger devices
@media (min-width: 769px) {
    .about-section {
        flex-direction: row; // Adjusted to position the image on the right

        .about-image,
        .about-text {
            max-height: 300px;  // Reduced max-height to make the card more compact
            width: 45%;  // Adjusted width to make the card more compact
        }
    }
}

// Responsive adjustments for phones and smaller devices
@media (max-width: 768px) {

    .about-section {
        flex-direction: column; // Stack vertically for mobile
        margin-bottom: 1.5rem; // Add margin between sections on smaller screens
        width: 90%;  // Adjusted width to make the card more compact

        .about-image {
            display: none; // Hide the image on smaller screens
        }

        .about-text {
            max-height: none;
            width: 100%;
            padding: 1rem;  // Reduced padding to make the card more compact
            text-align: center;

            h1 {
                font-size: 1.2rem; // Smaller header for mobile
            }

            p {
                font-size: 0.8rem; // Adjust paragraph font size for compactness
                margin-bottom: 0.5rem; // Reduced space between paragraphs for compactness
            }
        }
    }
}