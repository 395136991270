// src/components/dashboard/dashboard.scss

.dashboard-container {
  text-align: center;
  background-color: #f4f4f9;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard-buttons {
  margin: 20px 0;
}

.dashboard-buttons button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard-buttons button:hover {
  background-color: #45a049;
}

.dashboard-content {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0069d9;
}

.product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.product-card h2 {
  margin-top: 0;
}

.product-card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-card p {
  margin: 10px 0;
}

.product-card button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff5733;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-card button:hover {
  background-color: #e04e1b;
}

.order-list {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.order-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-card p {
  margin: 5px 0;
}