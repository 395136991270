// src/components/landing/style/footer.scss

.footer {
    background-color: #333333; // Dark grey background
    color: #ffffff; // White text color
    padding: 2rem;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 5rem;

    .footer-top {
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 1rem;

        .contact-info {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .icon {
                font-size: 1.2rem;
                color: #ffffff;
            }

            span {
                color: #ffffff;
            }
        }
    }

    .footer-social {
        margin-bottom: 1rem;

        .icon {
            font-size: 1.5rem;
            color: #ffffff;
            transition: color 0.3s;

            &:hover {
                color: #cccccc;
            }
        }
    }

    .footer-info {
        p {
            margin: 0.3rem 0;
        }
    }
}

// Responsive adjustments
@media (max-width: 768px) {
    .footer-top {
        flex-direction: column;
        align-items: center;
    }

    .footer-info {
        p {
            font-size: 0.8rem;
        }
    }
}
